<template>
  <div class="impact">
    <div class="impact__wrapper">
      <h2 class="impact__title" v-text="title" />
      <tz-markdown
        class="impact__copy"
        v-for="item in renderIf({ condition: copy })"
        :key="`copy_${item}`"
        :content="copy"
      />
      <ul class="impact__list">
        <li class="impact__card" v-for="item in list" :key="item.title">
          <img
            class="impact__card-img"
            :src="item.image"
            :alt="item.title"
            loading="lazy"
          />
          <div class="impact__card-content">
            <h3 class="impact__card-title" v-text="item.title" />
            <tz-markdown class="impact__card-copy" :content="item.copy" />
          </div>
        </li>
      </ul>
      <tz-markdown
        class="impact__copy"
        :content="subCopy"
        v-for="item in renderIf({ condition: subCopy })"
        :key="`sub-copy_${item}`"
      />
    </div>
  </div>
</template>

<script>
// Components
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// Helpers
import renderIf from '@/assets/js/shared/helpers/renderIf';

export default {
  name: 'impact',
  components: {
    TzMarkdown
  },
  props: {
    /**
     * Title of the section
     */
    title: {
      type: String
    },
    /**
     * Copy of the section
     */
    copy: {
      type: String
    },
    /**
     * Each list item has the following properties:
     * @typedef {Object} Item
     * @property {string} title
     * @property {string} copy
     * @property {string} image
     */
    list: {
      type: Array
    },
    /**
     * Optional copy
     */
    subCopy: {
      type: String
    }
  },
  methods: {
    renderIf
  }
};
</script>
