<template>
  <div class="what-data">
    <div class="what-data__wrapper">
      <h2
        class="what-data__title"
        v-text="title"
        v-for="item in renderIf({ condition: title })"
        :key="`title_${item}`"
      />
      <tz-markdown class="what-data__copy" :content="copy" />
      <ul class="what-data__list">
        <li v-for="item in items" :key="item.title" class="what-data__card">
          <img
            class="what-data__card-icon"
            :src="item.icon"
            :alt="item.title"
            loading="lazy"
          />
          <h3 class="what-data__card-title" v-text="item.title" />
        </li>
      </ul>
      <slot name="primary" />
      <ul
        class="what-data__list"
        v-for="item in renderIf({ condition: extraItems })"
        :key="`extra_${item}`"
      >
        <li
          v-for="item in extraItems"
          :key="item.title"
          class="what-data__card"
        >
          <img
            class="what-data__card-icon"
            :src="item.icon"
            :alt="item.title"
            loading="lazy"
          />
          <h3 class="what-data__card-title" v-text="item.title" />
        </li>
      </ul>
      <slot name="secondary" />
      <div
        class="what-data__cta"
        v-for="item in renderIf({ condition: ctaCopy })"
        :key="`cta_${item}`"
      >
        <tz-markdown class="what-data__cta-copy" :content="ctaCopy" />
        <component
          :is="componentType"
          v-bind="componentProps"
          class="what-data__cta-btn"
          v-text="buttonText"
          @click="handleClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// Helpers
import renderIf from '@/assets/js/shared/helpers/renderIf';
export default {
  name: 'what-data',
  components: {
    TzMarkdown
  },
  props: {
    /**
     * The title of the section
     */
    title: {
      type: String
    },
    /**
     * The markdown copy of the section
     */
    copy: {
      type: String
    },
    /**
     * Each item has the following properties:
     * @typedef {Object} Item
     * @property {string} icon
     * @property {string} title
     */
    items: {
      type: Array
    },
    /**
     * Each item has the following properties:
     * @typedef {Object} Item
     * @property {string} icon
     * @property {string} title
     */
    extraItems: {
      type: Array
    },
    buttonText: {
      type: String
    },
    /**
     * CTA markdown copy
     */
    ctaCopy: {
      type: String
    },
    /**
     * Optional CTA link
     */
    ctaLink: {
      type: String
    }
  },
  computed: {
    componentType() {
      return this.ctaLink ? 'a' : 'button';
    },
    componentProps() {
      return this.ctaLink ? { href: this.ctaLink } : {};
    }
  },
  methods: {
    renderIf,
    // Use CTA link if available. Otherwise emit event
    handleClick() {
      if (this.ctaLink) {
        return;
      }

      this.$emit('what-data-btn-click');
    }
  }
};
</script>
