<template>
  <div class="blog">
    <div class="blog__container">
      <header class="blog__header">
        <h2 class="blog__title" v-text="title" />
        <p class="blog__sub-text" v-text="subText" />
        <a class="blog__btn" :href="blogUrl" v-text="buttonText" />
      </header>
      <div class="blog__posts">
        <blog-post
          v-for="post in posts"
          :image="
            post?.image?.replace(
              'https://bgcsstaging.wpengine.com',
              `${blogUrl}`
            )
          "
          :title="post.title"
          :link="post.link"
          :published-date="post.date"
          :key="JSON.stringify(post)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BlogPost from '@/components/blog-post';

export default {
  name: 'blog',
  components: { BlogPost },
  data() {
    return {
      posts: [],
      blogUrl: process.env.BLOG_URL
    };
  },
  fetchOnServer: true,
  /**
   * Fetch blog posts
   * @returns {Promise<void>}
   */
  async fetch() {
    const domain = this.blogUrl
      .replace(/^https?:\/\/[^\/]+/g, '')
      .replace('/blog/', '');
    const posts = [];
    const media = [];
    const apiRoot = `wp-json/wp/v2/`;
    const apiUrl = `${this.blogUrl}${apiRoot}`;
    const TIMEOUTDURATION = 2000;

    /**
     * Get the published date
     * @param {object} opts
     * @param {string} opts.date
     * @returns {string}
     */
    const getPublishedDate = (opts = {}) => {
      if (!opts.date) {
        return '';
      }
      const date = new Date(opts.date);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    };

    try {
      this.$http.setHeader('Host', domain);

      const timeoutPromise = new Promise(resolve => {
        setTimeout(() => {
          resolve({ timeout: true });
        }, TIMEOUTDURATION);
      });

      const apiRequestPromise = await this.$http.$get(
        `${apiUrl}posts?_fields=title,link,date,_links.wp:featuredmedia&orderby=date&order=desc&per_page=4`
      );

      const rawPosts = await Promise.race([apiRequestPromise, timeoutPromise]);

      if (rawPosts.timeout) {
        throw new Error('Timeout');
      }

      for (let i = 0; i < rawPosts.length; i++) {
        if (i === 4) break;
        media[i] = parseInt(
          rawPosts[i]?._links['wp:featuredmedia'][0]?.href?.replace(
            `${apiUrl}media/`,
            ''
          )
        );
      }

      const mediaQuery = [...new Set(media)].join(',');
      const rawMedia = await this.$http.$get(
        `${apiUrl}media?_fields=media_details,id&include=${mediaQuery}`
      );
      // Format posts
      for (let i = 0; i < rawPosts.length; i++) {
        const image = rawMedia?.find(({ id }) => id === media[i]);
        posts[i] = {
          link: rawPosts[i]?.link?.toLowerCase(),
          title: rawPosts[i]?.title?.rendered,
          image: image?.media_details?.sizes.medium?.source_url,
          date: getPublishedDate({ date: rawPosts[i]?.date })
        };
      }
    } catch (e) {
      // Do nothing wpengine might be down
    }

    // Set the data
    this.posts = posts;
  },
  props: {
    title: {
      type: String,
      default: 'Recent Articles'
    },
    subText: {
      type: String,
      default: 'Read our up to date articles'
    },
    buttonText: {
      type: String,
      default: 'View More'
    }
  }
};
</script>
