<template>
  <div class="how-to" :class="{ 'how-to--numbered': hasCardNumber }">
    <div class="how-to__wrapper">
      <h2 class="how-to__title" v-text="title" />
      <tz-markdown
        class="how-to__copy"
        v-for="item in renderIf({ condition: copy })"
        :key="`copy_${item}`"
        :content="copy"
      />
      <button
        v-for="btn in renderIf({ condition: buttonText })"
        :key="btn"
        class="how-to__btn"
        v-text="buttonText"
        @click="handleClick"
      />
      <slot name="primary" />
      <ul class="how-to__list">
        <li
          class="how-to__item"
          v-for="(item, index) in items"
          :key="item.title"
        >
          <span
            v-for="cardNumber in renderIf({ condition: hasCardNumber })"
            :key="cardNumber"
            class="how-to__number"
          >
            {{ (index + 1).toString().padStart(2, '0') }}
          </span>
          <div class="how-to__card">
            <img
              class="how-to__card-icon"
              :src="item.icon"
              :alt="item.title"
              loading="lazy"
            />
            <h3 class="how-to__card-title" v-text="item.title" />
            <tz-markdown class="how-to__card-copy" :content="item.copy" />
          </div>
        </li>
      </ul>
      <slot name="secondary" />
    </div>
  </div>
</template>

<script>
// Components
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// Helpers
import renderIf from '@/assets/js/shared/helpers/renderIf';
export default {
  components: {
    TzMarkdown
  },
  methods: {
    renderIf,
    handleClick() {
      this.$emit('how-to-btn-click');
    }
  },
  props: {
    /**
     * Title of the section
     */
    title: {
      type: String
    },
    /**
     * Copy of the section
     */
    copy: {
      type: String
    },
    /**
     * Each list item has the following properties:
     * @typedef {Object} Item
     * @property {string} icon
     * @property {string} title
     * @property {string} copy
     */
    items: {
      type: Array
    },
    /**
     * Optional button text to display
     */
    buttonText: {
      type: String
    },
    /**
     * Show/hide the number listed for each card
     */
    hasCardNumber: {
      type: Boolean,
      default: true
    }
  }
};
</script>
