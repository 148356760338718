<template>
  <div class="ways-to-search">
    <div class="ways-to-search__wrapper">
      <h2 class="ways-to-search__title" v-text="title" />
      <tz-markdown class="ways-to-search__copy" :content="copy" />
      <ul class="ways-to-search__list">
        <li v-for="item in list" :key="item.title" class="ways-to-search__card">
          <div class="ways-to-search__card-wrapper">
            <h3 class="ways-to-search__card-title" v-text="item.title" />
            <tz-markdown
              class="ways-to-search__card-copy"
              :content="item.copy"
            />
            <a
              v-for="btn in renderIf({ condition: item.button_text })"
              :key="btn"
              class="ways-to-search__card-button"
              v-text="item.button_text"
              :href="item.url"
            />
          </div>
          <div class="ways-to-search__card-img-wrapper">
            <img
              class="ways-to-search__card-img"
              :src="item.image"
              :alt="item.title"
              loading="lazy"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
// Helpers
import renderIf from '@/assets/js/shared/helpers/renderIf';
export default {
  components: {
    TzMarkdown
  },
  methods: {
    renderIf
  },
  props: {
    /**
     * The title of the section
     */
    title: {
      type: String
    },
    /**
     * The markdown copy of the section
     */
    copy: {
      type: String
    },
    /**
     * Each item has the following properties:
     * @typedef {Object} Item
     * @property {string} title
     * @property {string} copy
     * @property {string} image
     * @property {string} buttonText
     * @property {string} url
     */
    list: {
      type: Array
    }
  }
};
</script>
