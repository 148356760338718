<template>
  <div class="faq">
    <div class="faq__wrapper">
      <h2 class="faq__title" v-text="title" />
      <tz-markdown class="faq__copy" :content="copy" />
      <tz-qna
        :items="items"
        :has-shuffle="false"
        :has-markdown="true"
        :question-icon="qnaIconQuestion"
      />
      <button
        class="faq__button"
        v-text="buttonText"
        v-touch:tap="handleBtnClick"
      />
    </div>
  </div>
</template>

<script>
import TzQna from '@trazi/tz-qna/src/tz-qna.vue';
import TzMarkdown from '@trazi/tz-markdown/src/tz-markdown.vue';
import qnaIconQuestion from '@/assets/images/icons/question-circle.svg';
export default {
  components: {
    TzMarkdown,
    TzQna
  },
  data() {
    return {
      qnaIconQuestion
    };
  },
  props: {
    title: {
      type: String
    },
    copy: {
      type: String
    },
    items: {
      type: Array
    },
    buttonText: {
      type: String
    }
  },
  methods: {
    handleBtnClick() {
      this.$emit('faq-btn-click');
    }
  }
};
</script>
