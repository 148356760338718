<template>
  <a class="blog-post" :href="link">
    <img class="blog-post__img" :src="image" :alt="title" loading="lazy" />
    <p
      class="blog-post__published"
      v-for="date in renderIf({ condition: publishedDate })"
      :key="date"
      v-html="`Published: <span>${publishedDate}</span>`"
    />
    <h3 class="blog-post__title" v-html="title" />
    <button class="blog-post__btn" v-text="buttonText" />
  </a>
</template>

<script>
import renderIf from '@/assets/js/shared/helpers/renderIf';
export default {
  name: 'blog-post',
  props: {
    image: {
      type: String
    },
    title: {
      type: String
    },
    link: {
      type: String
    },
    publishedDate: {
      type: String
    },
    buttonText: {
      type: String,
      default: 'Read More'
    }
  },
  data() {
    return {
      renderIf
    };
  }
};
</script>
